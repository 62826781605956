import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableCardComponent } from './table-card.component';
import { ImageLoaderModule } from '@fc-shared/ui/image-loader/image-loader.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [TableCardComponent],
  imports: [
    CommonModule,
    ImageLoaderModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    RouterModule,
  ],
  exports: [TableCardComponent],
})
export class TableCardModule {}
